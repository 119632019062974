/* eslint-disable camelcase */
import { WaleStatus, WaleStatusUptime } from 'domain/entities/waleStatus.entity';
import { MaintenanceStatus } from 'domain/entities/wale.entity';
import { API } from './api';

enum Path {
  WALE_STATUS = '/wale_statuses',
  WALE_STATUS_UPTIME = '/wale_statuses/uptime'
}

interface ElectricData {
  voltage?: number;
  current?: number;
  power?: number;
}

interface WaleStatusApiInterface {
  battery: ElectricData;
  solar: ElectricData;
  measuredAt: Date;
  temp: {
    cpu: number;
  };
  disk: {
    used: number;
    total: number;
  };
  system?: {
    cpu_usage?: number;
    memory_usage?: number;
    disk_used?: number;
    disk_total?: number;
    capture_queue?: number;
    oldest_capture_timestamp?: Date;
  };
  wale: string;
  target_0: number;
  automatic_brightness: number;
  system_schedule_on: boolean | null;
  vehicle_schedule_on: boolean | null;
  maintenance_status: MaintenanceStatus[];
}

const formatWaleStatus = (waleStatus: WaleStatusApiInterface): WaleStatus => ({
  ...waleStatus,
  system: waleStatus.system
    ? {
        cpuUsage: waleStatus.system.cpu_usage,
        memoryUsage: waleStatus.system.memory_usage,
        diskUsed: waleStatus.system.disk_used,
        diskTotal: waleStatus.system.disk_total,
        captureQueue: waleStatus.system.capture_queue,
        oldestCaptureTimestamp: waleStatus.system.oldest_capture_timestamp
      }
    : undefined,
  targetZero: waleStatus.target_0,
  autoBrightness: waleStatus.automatic_brightness === 1,
  systemScheduleOn: waleStatus.system_schedule_on === true,
  vehicleScheduleOn: waleStatus.vehicle_schedule_on === true,
  maintenanceStatus: waleStatus.maintenance_status
});

export const getLastWaleStatus = async (
  endDate: Date,
  waleId: string
): Promise<WaleStatus | null> => {
  const { data: waleStatus } = await API.get<WaleStatusApiInterface[]>(Path.WALE_STATUS, {
    params: { end_date: endDate.valueOf(), wale_id: waleId, limit: 1 }
  });

  return waleStatus.length ? formatWaleStatus(waleStatus[0]) : null;
};

export const getWaleStatuses = (
  startDate: Date,
  endDate: Date,
  waleId: string
): Promise<WaleStatus[]> =>
  API.get<WaleStatusApiInterface[]>(Path.WALE_STATUS, {
    params: {
      start_date: startDate.valueOf(),
      end_date: endDate.valueOf(),
      wale_id: waleId,
      limit: 3000
    }
  }).then((response) => response.data.map(formatWaleStatus));

export const getWaleStatusesCount = (
  startDate: Date,
  endDate: Date,
  waleId: string
): Promise<WaleStatusUptime[]> =>
  API.get<WaleStatusUptime[]>(Path.WALE_STATUS_UPTIME, {
    params: {
      start_date: startDate.valueOf(),
      end_date: endDate.valueOf(),
      wale_id: waleId,
      limit: 3000
    }
  }).then((response) => response.data);
