import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { useWaleIdsByLocationAndLane } from 'presentation/hooks/useWaleIdsByLocationAndLane';
import Select from 'components/Select';
import customStyles from 'components/Select/themes';
import { getLocationHumanNameMap, getRoadDirection } from 'presentation/helpers/waleId';
import { KeyCode, useShortcut } from 'presentation/hooks/useShortcut';
import { useQueryParam } from 'presentation/hooks/useQueryParam';
import { useWales } from 'presentation/hooks/useWaleList';
import useInterval from 'presentation/hooks/useInterval';
import { useSetQueryParam } from 'presentation/hooks/useSetQueryParam';
import { range, sum } from 'lodash';
import { mapToOptions } from 'components/Select/FormikSelect';
import SingleLocationMonitor from './components/SingleLocationMonitor';
import { CorridorDisplay } from './components/CorridorDisplay/CorridorDisplay';
import { useCorridorData } from './components/CorridorDisplay/CorridorDisplay.view-model';
import {
  getCarouselLocationIds,
  getSelectedLocations,
  getWalePairsToShow
} from './WaleMonitor.view-model';
import { CarouselControls } from './components/CorridorDisplay/CarouselControls';

const WARNING_MINUTES_OPTIONS = [3, 5, 10];
const DANGER_MINUTES_OPTIONS = [5, 10, 20];
const CAROUSEL_SECONDS_OPTIONS = [3, 5, 10, 30];
const centerStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center' };

const projectDirections: Record<string, string[]> = {
  'CO-I70': ['W', 'E'],
  'CO-C470': ['W', 'E'],
  'CO-I25': ['S', 'N'],
  'BW-HQ': ['', 'B'],
  'CO-C70': ['_W', '_E'],
  'CO-I25SG': ['_S', '_N'],
  'CO-US36': ['_W', '_E'],
  'NC-I540': ['_W', '_E'],
};

enum EmptySpaceLevel {
  ALL = 0,
  SOME = 1,
  NONE = 2
}

enum WaleTypeOption {
  PROD = 0,
  DEV = 1,
  ALL = 2
}

const MAX_WALES_IN_ROW_OPTIONS = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const EMPTY_SPACE_LEVELS_OPTIONS = [
  { label: 'Old Format', value: EmptySpaceLevel.ALL, queryString: 'all' },
  { label: 'FB Blocks', value: EmptySpaceLevel.SOME, queryString: 'some' },
  { label: 'None', value: EmptySpaceLevel.NONE, queryString: 'none' }
];

const CAROUSEL_COUNT_OPTIONS = mapToOptions(range(1, 11));
const CAROUSEL_SKIP_OPTIONS = mapToOptions(range(1, 11));

const getInitialCorridors = (project: string) => {
  if (project in projectDirections) {
    return projectDirections[project].map((direction) => project + direction);
  }
  return ['NO-ROAD', 'NO-ROAD'];
};

const parseInitialHiddenWaleLanes = (initialEncodedParam: string | null) => {
  try {
    return (
      initialEncodedParam
        ?.split(',')
        .map((a) => a.split(':'))
        .map(([waleId, lane]) => ({ waleId, lane: parseInt(lane, 10) })) ?? []
    );
  } catch {
    return [];
  }
};

export const WaleMonitor = () => {
  const projectParam = useQueryParam('project');
  const layoutParam = useQueryParam('layout');
  const project = sessionStorage.getItem('project') ?? projectParam ?? '';
  const [firstCorridor, secondCorridor] = getInitialCorridors(project);

  const waleTypeStr = useQueryParam('waleType') as keyof typeof WaleTypeOption;
  const initialWaleType = WaleTypeOption[waleTypeStr] ?? WaleTypeOption.PROD;
  const [waleTypesToShow, setWaleTypesToShow] = useState<WaleTypeOption>(initialWaleType);

  const waleIdsByLocationAndLane = useWaleIdsByLocationAndLane();
  const wales = useWales();
  const humanNameMap = getLocationHumanNameMap(wales);

  const [waleIds, setWaleIds] = useState<string[]>([]);
  const [noWalesToShow, setNoWalesToShow] = useState(false);

  useEffect(() => {
    const showProd = [WaleTypeOption.PROD, WaleTypeOption.ALL].includes(waleTypesToShow);
    const showDev = [WaleTypeOption.DEV, WaleTypeOption.ALL].includes(waleTypesToShow);
    const filteredWales = wales
      .filter((w) => w.active && (showProd === w.isProduction || showDev === !w.isProduction))
      .map((w) => w.waleId);
    setWaleIds(filteredWales);
    setNoWalesToShow(wales.length > 0 && filteredWales.length === 0);
  }, [wales, waleTypesToShow]);

  const setQueryParam = useSetQueryParam();

  const [pause, setPause] = useState(false);
  const [warningMinutes, setWarningMinutes] = useState(WARNING_MINUTES_OPTIONS[0]);
  const [dangerMinutes, setDangerMinutes] = useState(DANGER_MINUTES_OPTIONS[0]);
  const [carouselSeconds, setCarouselSeconds] = useState(CAROUSEL_SECONDS_OPTIONS[1]);
  const defaultMaxWalesPerRow = MAX_WALES_IN_ROW_OPTIONS[1];
  const [maxWalesPerRow, setMaxWalesPerRow] = useState(
    parseInt(useQueryParam('maxWalesPerRow') ?? defaultMaxWalesPerRow.toString(), 10)
  );
  const [emptySpaceLevel, setEmptySpaceLevel] = useState(
    EMPTY_SPACE_LEVELS_OPTIONS.find((opt) => opt.queryString === layoutParam)?.value ??
      EmptySpaceLevel.SOME
  );
  const [splitWaleLanes, setSplitWaleLanes] = useState(true);

  const [hiddenWaleLanes, setHiddenWaleLanes] = useState<{ waleId: string; lane: number }[]>(
    parseInitialHiddenWaleLanes(useQueryParam('hiddenWaleLanes'))
  );
  const [showHideWaleButton, setShowHideWaleButton] = useState(false);
  const [hideUnderMaintenance, setHideUnderMaintenance] = useState(
    useQueryParam('hideUnderMaintenance') === 'true'
  );
  const [hideVDOff, setHideVDOff] = useState(useQueryParam('hideVDOff') === 'true');
  const [hideSystemOff, setHideSystemOff] = useState(useQueryParam('hideSystemOff') === 'true');

  const [displayMetroLine, setDisplayMetroLine] = useState(true);
  const [advancedSettings, setAdvancedSettings] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState<number | null>(null);
  const [carouselLocationCount, setCarouselLocationCount] = useState(3);
  const [carouselLocationSkip, setCarouselLocationSkip] = useState(1);
  const [carouselPaused, setCarouselPaused] = useState(false);

  const initialLocations = useQueryParam('locations')?.split(',') ?? [];

  const safeSetCarouselIndex = (num: number | null) =>
    setCarouselIndex(Number.isNaN(num) ? 0 : num);

  const {
    corridorLocations: firstCorridorLocations,
    setCorridorLocations: setFirstCorridorLocations,
    isCorridorAscending: isFirstCorridorAscending,
    lanes: firstCorridorLanes,
    gplStart: firstGplStart
  } = useCorridorData(
    firstCorridor,
    waleIds,
    carouselIndex,
    safeSetCarouselIndex,
    initialLocations
  );
  const {
    corridorLocations: secondCorridorLocations,
    setCorridorLocations: setSecondCorridorLocations,
    isCorridorAscending: isSecondCorridorAscending,
    lanes: secondCorridorLanes,
    gplStart: secondGplStart
  } = useCorridorData(
    secondCorridor,
    waleIds,
    carouselIndex,
    safeSetCarouselIndex,
    initialLocations
  );

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === ' ') {
      setPause(!pause);
    }
  };

  useShortcut([KeyCode.ControlLeft, KeyCode.M], () => {
    setDisplayMetroLine(!displayMetroLine);
  });

  const valueToSelectValue = (opt: number) => ({ label: opt, value: opt });
  const walesToShowToSelectValue = (opt: WaleTypeOption) => ({
    label: WaleTypeOption[opt],
    value: opt
  });

  const firstSelectedLocations = getSelectedLocations(
    firstCorridorLocations,
    isFirstCorridorAscending
  );
  const secondSelectedLocations = getSelectedLocations(
    secondCorridorLocations,
    isSecondCorridorAscending
  );
  const selectedLocations = [...firstSelectedLocations, ...secondSelectedLocations];

  const carouselLocations = getCarouselLocationIds(
    selectedLocations,
    carouselIndex,
    carouselLocationCount
  );

  const walePairsToShow = getWalePairsToShow(
    waleIdsByLocationAndLane,
    selectedLocations,
    waleIds,
    carouselLocations
  );

  useEffect(() => {
    setQueryParam('waleType', WaleTypeOption[waleTypesToShow]);
  }, [waleTypesToShow]);

  useEffect(() => {
    setQueryParam('maxWalesPerRow', maxWalesPerRow.toString());
  }, [maxWalesPerRow]);

  useEffect(() => {
    setQueryParam(
      'layout',
      EMPTY_SPACE_LEVELS_OPTIONS.find((opt) => opt.value === emptySpaceLevel)?.queryString ?? ''
    );
  }, [emptySpaceLevel]);

  useEffect(() => {
    setQueryParam('hideUnderMaintenance', hideUnderMaintenance.toString());
  }, [hideUnderMaintenance]);

  useEffect(() => {
    setQueryParam(
      'hiddenWaleLanes',
      hiddenWaleLanes.map((pair) => `${pair.waleId}:${pair.lane}`).join(',')
    );
  }, [hiddenWaleLanes]);

  useInterval(() => {
    if (carouselIndex !== null && !carouselPaused) {
      safeSetCarouselIndex((carouselIndex + carouselLocationSkip) % selectedLocations.length);
    }
  }, carouselSeconds * 1000);

  const hideWaleLane = (waleId?: string, lane?: number) => {
    if (
      waleId &&
      lane &&
      !hiddenWaleLanes.find(
        (hiddenElem) => hiddenElem.waleId === waleId && hiddenElem.lane === lane
      )
    )
      setHiddenWaleLanes([...hiddenWaleLanes, { waleId, lane }]);
  };

  const resetHiddenWales = () => setHiddenWaleLanes([]);

  const progressText =
    !noWalesToShow &&
    (firstCorridorLanes.length === 0 || secondCorridorLanes.length === 0 || wales.length === 0)
      ? 'Loading...'
      : 'No WALEs';

  return (
    <div onKeyDown={handleKeyPress} role="button" tabIndex={0} className="d-flex flex-column my-3">
      <Row className={`!grid full-height grid-cols-8 3xl:grid-cols-${maxWalesPerRow > 6 ? 8 : 6}`}>
        {displayMetroLine && (
          <Col className="col-span-8 md:col-span-4 lg:col-span-3 xl:col-span-2 3xl:col-span-1">
            {waleIds.length === 0 || project === 'All' ? (
              <Row>
                <Col xs={{ span: 8, offset: 2 }} style={{ paddingBottom: '4em' }}>
                  <h4>{project === 'All' ? 'Select a Project' : progressText}</h4>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xs={6} style={{ padding: 0 }}>
                  <CorridorDisplay
                    roadTitle={getRoadDirection(firstCorridor)}
                    roadColor="#e21e80"
                    corridorLocations={firstCorridorLocations}
                    setCorridorLocations={setFirstCorridorLocations}
                    lanes={firstCorridorLanes}
                    isCorridorAscending={isFirstCorridorAscending}
                    humanNameMap={humanNameMap}
                    carouselLocations={carouselLocations}
                    gplStart={firstGplStart}
                  />
                </Col>
                <Col xs={6} style={{ padding: 0 }}>
                  <CorridorDisplay
                    roadTitle={getRoadDirection(secondCorridor)}
                    roadColor="#cc8800"
                    corridorLocations={secondCorridorLocations}
                    setCorridorLocations={setSecondCorridorLocations}
                    lanes={secondCorridorLanes}
                    isCorridorAscending={isSecondCorridorAscending}
                    humanNameMap={humanNameMap}
                    carouselLocations={carouselLocations}
                    gplStart={secondGplStart}
                  />
                </Col>
              </Row>
            )}
            <Row style={{ paddingLeft: '2em', marginBottom: '2em' }} className="gap-4">
              <Col>
                <Toggle
                  defaultChecked={carouselIndex !== null}
                  onChange={() => safeSetCarouselIndex(carouselIndex === null ? 0 : null)}
                />
                Carousel
              </Col>
              <Col>
                <Toggle
                  defaultChecked={splitWaleLanes}
                  onChange={(e) => {
                    setSplitWaleLanes(e.target.checked);
                  }}
                />
                Split Lanes
              </Col>
            </Row>
            <Row style={{ paddingLeft: '2em', marginBottom: '2em' }} className="gap-4">
              <Col>
                <Toggle
                  defaultChecked={advancedSettings}
                  onChange={() => setAdvancedSettings(!advancedSettings)}
                />
                Settings
              </Col>
              <Col>
                {carouselIndex !== null ? (
                  <CarouselControls
                    carouselIndex={carouselIndex}
                    setCarouselIndex={safeSetCarouselIndex}
                    carouselPaused={carouselPaused}
                    setCarouselPaused={setCarouselPaused}
                    selectedLocationsLength={selectedLocations.length}
                  />
                ) : null}
              </Col>
            </Row>
            {advancedSettings ? (
              <Row className="gap-4">
                <Row>
                  <Row style={{ paddingLeft: '2em' }} className="gap-4">
                    <Col style={centerStyle}>Layout Settings:</Col>
                  </Row>
                  <Row style={{ paddingLeft: '2em' }} className="gap-4">
                    <Col className="rows-select" style={centerStyle}>
                      Max WALEs per row:
                      <Select
                        styles={customStyles}
                        defaultValue={valueToSelectValue(maxWalesPerRow)}
                        isDisabled={false}
                        isSearchable={false}
                        options={MAX_WALES_IN_ROW_OPTIONS.map(valueToSelectValue)}
                        onChange={(e: { value: number }) => setMaxWalesPerRow(e.value)}
                      />
                    </Col>
                    <Col className="rows-select" style={centerStyle}>
                      Grid Layout:
                      <Select
                        styles={customStyles}
                        defaultValue={EMPTY_SPACE_LEVELS_OPTIONS[emptySpaceLevel]}
                        isDisabled={false}
                        isSearchable={false}
                        options={EMPTY_SPACE_LEVELS_OPTIONS}
                        onChange={(e: { value: EmptySpaceLevel }) => setEmptySpaceLevel(e.value)}
                      />
                    </Col>
                    <Row style={{ paddingLeft: '2em' }} className="gap-4">
                      <Col>
                        <Toggle
                          defaultChecked={showHideWaleButton}
                          onChange={(e) => {
                            setShowHideWaleButton(e.target.checked);
                          }}
                        />
                        Show &apos;Hide WALE&apos; Button
                      </Col>
                      <Col>
                        <Row className="d-flex justify-content-around align-items-center">
                          <Button
                            style={{ paddingTop: '3px', paddingBottom: '3px', borderRadius: '1em' }}
                            onClick={resetHiddenWales}
                          >
                            Reset Hidden
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                  </Row>
                </Row>
                {carouselIndex !== null ? (
                  <Row>
                    <Row style={{ paddingLeft: '2em' }} className="gap-4">
                      <Col style={centerStyle}>Carousel Settings:</Col>
                    </Row>
                    <Row style={{ paddingLeft: '2em' }} className="gap-4">
                      <Col className="rows-select" style={centerStyle}>
                        Timer (seconds):
                        <Select
                          styles={customStyles}
                          defaultValue={valueToSelectValue(carouselSeconds)}
                          isDisabled={false}
                          isSearchable={false}
                          options={CAROUSEL_SECONDS_OPTIONS.map(valueToSelectValue)}
                          onChange={(e: { value: number }) => setCarouselSeconds(e.value)}
                        />
                      </Col>
                      <Col className="rows-select" style={centerStyle}>
                        Location Count:
                        <Select
                          styles={customStyles}
                          defaultValue={CAROUSEL_COUNT_OPTIONS[carouselLocationCount - 1]}
                          isDisabled={false}
                          isSearchable={false}
                          options={CAROUSEL_COUNT_OPTIONS}
                          onChange={(e: { value: number }) => setCarouselLocationCount(e.value)}
                        />
                      </Col>
                      <Col className="rows-select" style={centerStyle}>
                        Auto Skip:
                        <Select
                          styles={customStyles}
                          defaultValue={CAROUSEL_SKIP_OPTIONS[carouselLocationSkip - 1]}
                          isDisabled={false}
                          isSearchable={false}
                          options={CAROUSEL_SKIP_OPTIONS}
                          onChange={(e: { value: number }) => setCarouselLocationSkip(e.value)}
                        />
                      </Col>
                    </Row>
                  </Row>
                ) : null}
                <Row>
                  <Row style={{ paddingLeft: '2em' }} className="gap-4">
                    <Col style={centerStyle}>Alert Settings</Col>
                  </Row>
                  <Row style={{ paddingLeft: '2em' }} className="gap-4">
                    <Col className="rows-select" style={centerStyle}>
                      Warning (minutes):
                      <Select
                        styles={customStyles}
                        defaultValue={valueToSelectValue(warningMinutes)}
                        isDisabled={false}
                        isSearchable={false}
                        options={WARNING_MINUTES_OPTIONS.map(valueToSelectValue)}
                        onChange={(e: { value: number }) => setWarningMinutes(e.value)}
                      />
                    </Col>
                    <Col className="rows-select" style={centerStyle}>
                      Danger (minutes):
                      <Select
                        styles={customStyles}
                        defaultValue={valueToSelectValue(dangerMinutes)}
                        isDisabled={false}
                        isSearchable={false}
                        options={DANGER_MINUTES_OPTIONS.map(valueToSelectValue)}
                        onChange={(e: { value: number }) => setDangerMinutes(e.value)}
                      />
                    </Col>
                  </Row>
                </Row>
                <Row style={{ paddingLeft: '2em', marginBottom: '2em' }} className="gap-4">
                  <Col className="rows-select" style={centerStyle}>
                    WALEs to show
                    <Select
                      styles={customStyles}
                      defaultValue={walesToShowToSelectValue(waleTypesToShow)}
                      isDisabled={false}
                      isSearchable={false}
                      options={[WaleTypeOption.PROD, WaleTypeOption.DEV, WaleTypeOption.ALL].map(
                        walesToShowToSelectValue
                      )}
                      onChange={(e: { value: WaleTypeOption }) => setWaleTypesToShow(e.value)}
                    />
                  </Col>
                </Row>
                <Row style={{ paddingLeft: '2em', marginBottom: '2em' }} className="gap-4">
                  <Col>
                    <Toggle
                      defaultChecked={hideUnderMaintenance}
                      onChange={(e) => {
                        setHideUnderMaintenance(e.target.checked);
                      }}
                    />
                    Hide Under Maintenance
                  </Col>
                </Row>
                <Row style={{ paddingLeft: '2em', marginBottom: '2em' }} className="gap-4">
                  <Col>
                    <Toggle
                      defaultChecked={hideVDOff}
                      onChange={(e) => {
                        setHideVDOff(e.target.checked);
                      }}
                    />
                    Hide VD off
                  </Col>
                  <Col>
                    <Toggle
                      defaultChecked={hideSystemOff}
                      onChange={(e) => {
                        setHideSystemOff(e.target.checked);
                      }}
                    />
                    Hide System Off
                  </Col>
                </Row>
              </Row>
            ) : null}
          </Col>
        )}
        <Col
          className={`full-height bliss-card-solid-bg tokens-overscroll col-span-full ${
            displayMetroLine
              ? `md:col-span-4 lg:col-span-5 xl:col-span-6 3xl:col-span-${
                  maxWalesPerRow > 6 ? 7 : 5
                }`
              : ''
          }`}
        >
          <Col className="roadside-map-wrapper">
            <Row
              className={`monitor-row !grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-${maxWalesPerRow}`}
            >
              {walePairsToShow.map((locationWales, index) => {
                const waleIndex = sum(
                  walePairsToShow
                    .slice(0, index)
                    .map((pair) => (pair.back ? 1 : 0) + (pair.front ? 1 : 0))
                );
                return locationWales.front || locationWales.back ? (
                  <SingleLocationMonitor
                    key={`single-loc-${locationWales.front}-${locationWales.back}`}
                    front={locationWales.front}
                    back={locationWales.back}
                    noEmptySpace={emptySpaceLevel === EmptySpaceLevel.NONE}
                    splitLanes={splitWaleLanes}
                    isAscendingMileage={locationWales.isAscendingMileage}
                    hiddenWaleLanes={hiddenWaleLanes}
                    singleMonitorProps={{
                      pause,
                      warningMinutes,
                      dangerMinutes,
                      index: waleIndex,
                      hideWaleLane,
                      showHideWaleButton
                    }}
                    frontBackProps={{
                      ignoreEmptySpace: emptySpaceLevel !== EmptySpaceLevel.ALL,
                      hideUnderMaintenance,
                      hideVDOff,
                      hideSystemOff
                    }}
                  />
                ) : null;
              })}
            </Row>
          </Col>
        </Col>
      </Row>
    </div>
  );
};
