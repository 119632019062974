import { useState } from 'react';
import { getRoadVeryShortDateTime } from '../../../services/timeUtils';
import { WaleStatusMonitor } from './StatusTable';

export const useStatusData = (statusData: WaleStatusMonitor) => {
  const [openPopUp, setOpenPopUp] = useState(false);

  const handleClick = () => setOpenPopUp(!openPopUp);
  const popUpStyle = openPopUp ? 'pop-up' : 'pop-up d-none';

  const time = statusData.measuredAt ? getRoadVeryShortDateTime(statusData.measuredAt) : '';
  const unixTime = statusData.measuredAt;
  const batteryVoltage = statusData.battery.voltage;
  const solarPower = statusData.solar.power ? statusData.solar.power.toFixed(2) : '-';
  const cpuTemperature = statusData.temp.cpu ? statusData.temp.cpu.toFixed(1) : '-';
  const cpuUsage =
    statusData.system && statusData.system.cpuUsage
      ? Math.round(statusData.system.cpuUsage * 100)
      : '-';
  const memoryUsage =
    statusData.system && statusData.system.memoryUsage
      ? Math.round(statusData.system.memoryUsage * 100)
      : '-';
  const diskUsage =
    statusData.system && statusData.system.diskUsed && statusData.system.diskTotal
      ? Math.round((statusData.system.diskUsed / statusData.system.diskTotal) * 100)
      : '';

  const { targetZero, autoBrightness } = statusData;

  return {
    time,
    targetZero,
    autoBrightness,
    unixTime,
    batteryVoltage,
    solarPower,
    cpuTemperature,
    cpuUsage,
    memoryUsage,
    diskUsage,
    popUpStyle,
    captureQueue: statusData.system?.captureQueue,
    oldestCaptureTimestamp: statusData.system?.oldestCaptureTimestamp,
    handleClick
  };
};
