/* eslint-disable no-underscore-dangle */
import React from 'react';
import memoize from 'memoize-one';
import { get } from 'lodash';
import {
  availableFWGammaValues,
  availableFWShutterSpeeds,
  SensysTransmitIntervals
} from 'domain/entities/wale.entity';
import { BasicActionButtons } from '../../components';
import EditableCell from '../../components/DataTable/EditableCell';

enum RowType {
  DISPLAY_ONLY = 'display_only',
  BOOLEAN = 'boolean',
  TEXT = 'text',
  MAINTENANCE_ARRAY = 'maintenance_array',
  SINGLE_SELECT = 'single_select',
  NOT_DISPLAYED = 'not_displayed'
}

const getRowType = (rowName: string) =>
  DISPLAYED_ROWS.find((row) => row.name === rowName)?.type ?? RowType.NOT_DISPLAYED;

const getRowDropdownOptions = (rowName: string) =>
  DISPLAYED_ROWS.find((row) => row.name === rowName)?.options;

// This list also defines the order the rows are displayed in.
const DISPLAYED_ROWS = [
  { name: 'active', type: RowType.BOOLEAN },
  { name: 'isProduction', type: RowType.DISPLAY_ONLY },
  { name: 'maintenanceStatus', type: RowType.MAINTENANCE_ARRAY },
  { name: 'eboxName', type: RowType.DISPLAY_ONLY },
  { name: 'eboxId', type: RowType.DISPLAY_ONLY },
  { name: 'configurations.system.device_version', type: RowType.DISPLAY_ONLY },
  { name: 'configurations.system.upload_photos', type: RowType.DISPLAY_ONLY },
  { name: 'configurations.system.schedule', type: RowType.TEXT },
  { name: 'configurations.system.capture_processor_threads', type: RowType.TEXT },
  { name: 'configurations.system.low_bandwidth_capture_compression', type: RowType.BOOLEAN },
  { name: 'configurations.system.force_capture_compression', type: RowType.BOOLEAN },
  { name: 'configurations.system.compressed_captures_jpeg_quality', type: RowType.TEXT },
  { name: 'configurations.vehicle_detector.schedule', type: RowType.TEXT },
  { name: 'configurations.vehicle_detector.lane_number', type: RowType.DISPLAY_ONLY },
  { name: 'configurations.vehicle_detector.lidar_onboard', type: RowType.DISPLAY_ONLY },
  { name: 'configurations.vehicle_detector.magnetometer_onboard', type: RowType.DISPLAY_ONLY },
  { name: 'configurations.camera.save_video', type: RowType.DISPLAY_ONLY },
  { name: 'configurations.camera.upload_video', type: RowType.BOOLEAN },
  { name: 'configurations.camera.stream_name', type: RowType.TEXT },
  { name: 'configurations.camera.stream_framerate', type: RowType.TEXT },
  {
    name: 'configurations.camera.camera_type',
    type: RowType.SINGLE_SELECT,
    options: ['ar', 'flexwatch']
  },
  // Brightness
  {
    name: 'configurations.camera.params.ar.automatic_brightness.enable_modul',
    type: RowType.TEXT
  },
  { name: 'configurations.camera.params.ar.brightness_general.target_0', type: RowType.TEXT },
  {
    name: 'configurations.camera.params.flexwatch.CameraSetup.Exposure.Brightness_Day',
    type: RowType.TEXT
  },
  {
    name: 'configurations.camera.params.flexwatch.CameraSetup.Exposure.BrightnessMin_Day',
    type: RowType.TEXT
  },

  // Iris/Shutter/Gain
  {
    name: 'configurations.camera.params.ar.brightness_sensor_1.iris_day_min',
    type: RowType.TEXT
  },
  {
    name: 'configurations.camera.params.ar.brightness_sensor_1.iris_day_max',
    type: RowType.TEXT
  },
  { name: 'configurations.camera.params.ar.brightness_sensor_1.iris_night', type: RowType.TEXT },
  {
    name: 'configurations.camera.params.ar.brightness_sensor_1.shutter_day_max',
    type: RowType.TEXT
  },
  {
    name: 'configurations.camera.params.flexwatch.CameraSetup.Exposure.ManualShutterSpeed_Day',
    type: RowType.SINGLE_SELECT,
    options: availableFWShutterSpeeds
  },
  {
    name: 'configurations.camera.params.flexwatch.CameraSetup.Image.Gamma_Day',
    type: RowType.SINGLE_SELECT,
    options: availableFWGammaValues
  },

  // Trigger offsets
  { name: 'configurations.camera.params.ar.trigger.end_offs', type: RowType.TEXT },
  { name: 'configurations.camera.params.ar.trigger.start_offs', type: RowType.TEXT },
  {
    name: 'configurations.camera.params.flexwatch.CustomParams.triggerEndOffset',
    type: RowType.TEXT
  },
  {
    name: 'configurations.camera.params.flexwatch.CustomParams.triggerStartOffset',
    type: RowType.TEXT
  },

  { name: 'configurations.camera.params.ar.hwlayer.zoom_pc', type: RowType.TEXT },
  { name: 'configurations.camera.params.ar.focus.focus_day', type: RowType.TEXT },
  { name: 'configurations.camera.params.ar.focus.focus_night', type: RowType.TEXT },
  { name: 'configurations.camera.embedded_trigger', type: RowType.TEXT },
  { name: 'configurations.lidar.trigger_on', type: RowType.BOOLEAN },
  { name: 'configurations.lidar.mindist', type: RowType.TEXT },
  { name: 'configurations.lidar.maxdist', type: RowType.TEXT },
  { name: 'configurations.lidar.lanedists', type: RowType.TEXT },

  { name: 'configurations.magnetometer.rf_channel', type: RowType.TEXT },
  { name: 'configurations.magnetometer.sensor_ids', type: RowType.TEXT },
  { name: 'configurations.magnetometer.sensor_lanes', type: RowType.TEXT },
  {
    name: 'configurations.magnetometer.transmit_interval',
    type: RowType.SINGLE_SELECT,
    options: SensysTransmitIntervals
  },
  { name: 'configurations.magnetometer.trigger_on', type: RowType.BOOLEAN },
  { name: 'configurations.magnetometer.trigger_pos_edge', type: RowType.BOOLEAN },
  { name: 'configurations.magnetometer.triggering_sensors', type: RowType.TEXT },
  { name: 'configurations.magnetometer.trigger_offsets', type: RowType.TEXT },
  { name: 'configurations.magnetometer.trigger_distance_offsets', type: RowType.TEXT },
  { name: 'configurations.magnetometer.avg_delay_ms', type: RowType.TEXT },

  // night illumination
  { name: 'configurations.camera.params.ar.brightness_general.ledpc_night', type: RowType.TEXT },
  { name: 'configurations.camera.params.ar.brightness_general.leddiff_night', type: RowType.TEXT },
  {
    name: 'configurations.camera.params.ar.brightness_sensor_1.shutter_night',
    type: RowType.TEXT
  },
  {
    name: 'configurations.camera.params.ar.brightness_sensor_1.llm_night',
    type: RowType.TEXT
  },
  {
    name: 'configurations.camera.params.flexwatch.CameraSetup.Exposure.Brightness_Night',
    type: RowType.TEXT
  },
  {
    name: 'configurations.camera.params.flexwatch.CameraSetup.Exposure.BrightnessMin_Night',
    type: RowType.TEXT
  },
  {
    name: 'configurations.camera.params.flexwatch.CustomParams.lightType',
    type: RowType.SINGLE_SELECT,
    options: ['WHITE_LIGHT', 'INFRARED']
  },
  {
    name: 'configurations.camera.params.flexwatch.CustomParams.ledFrequency',
    type: RowType.TEXT
  },
  {
    name: 'configurations.camera.params.flexwatch.CustomParams.ledIntensity',
    type: RowType.TEXT
  },

  {
    name: 'configurations.camera.params.flexwatch.CameraSetup.Exposure.Agc_Night',
    type: RowType.TEXT
  },
  {
    name: 'configurations.camera.params.flexwatch.CameraSetup.Image.Gamma_Night',
    type: RowType.SINGLE_SELECT,
    options: availableFWGammaValues
  },
  {
    name: 'configurations.camera.params.flexwatch.CameraSetup.Exposure.ManualShutterSpeed_Night',
    type: RowType.SINGLE_SELECT,
    options: availableFWShutterSpeeds
  },
  {
    name: 'configurations.camera.params.flexwatch.CameraSetup.DayNight.NightColor',
    type: RowType.TEXT
  },

  // Image settings
  {
    name: 'configurations.camera.params.flexwatch.CameraSetup.Image.Flip',
    type: RowType.SINGLE_SELECT,
    options: ['On', 'Off']
  },
  {
    name: 'configurations.camera.params.flexwatch.CameraSetup.Image.Mirror',
    type: RowType.SINGLE_SELECT,
    options: ['On', 'Off']
  },

  // Day Night switching
  {
    name: 'configurations.camera.dusk_dawn.day_threshold',
    type: RowType.TEXT
  },
  {
    name: 'configurations.camera.dusk_dawn.night_threshold',
    type: RowType.TEXT
  },
  {
    name: 'configurations.camera.dusk_dawn.day_hysteresis',
    type: RowType.TEXT
  },
  {
    name: 'configurations.camera.dusk_dawn.night_hysteresis',
    type: RowType.TEXT
  },
  {
    name: 'configurations.camera.params.flexwatch.CameraSetup.DayNight.DayNightMode',
    type: RowType.SINGLE_SELECT,
    options: ['Extern', 'Auto', 'Day', 'Night']
  },
  {
    name: 'configurations.camera.params.flexwatch.CameraSetup.DayNight.Day_to_Night_Threshold',
    type: RowType.TEXT
  },
  {
    name: 'configurations.camera.params.flexwatch.CameraSetup.DayNight.Night_to_Day_Threshold',
    type: RowType.TEXT
  }
];

export const DISPLAYED_ROWS_NAMES = DISPLAYED_ROWS.map((row) => row.name);

export const CONDITIONAL_ROW_STYLES = [
  {
    when: (row: { name: string }) =>
      row.name.includes('brightness_general.target_0') ||
      row.name.includes('Exposure.Brightness_Day') ||
      row.name.includes('Exposure.Brightness_Night') ||
      row.name.includes('Exposure.ManualShutterSpeed_Day') ||
      row.name.includes('Exposure.ManualShutterSpeed_Night'),
    style: { backgroundColor: '#faf887', color: 'black' }
  },
  {
    when: (row: { name: string }) => row.name.includes('configurations.system.schedule'),
    style: { backgroundColor: '#d65041', color: 'black' }
  },
  {
    when: (row: { name: string }) =>
      row.name.includes('trigger.end_offs') || row.name.includes('triggerEndOffset'),
    style: { backgroundColor: '#d65040', color: 'black' }
  },
  {
    when: (row: { name: string }) =>
      row.name.includes('trigger.start_offs') || row.name.includes('triggerStartOffset'),
    style: { backgroundColor: '#c93d2c', color: 'black' }
  },
  {
    when: (row: { name: string }) => row.name.includes('focus.focus_day'),
    style: { backgroundColor: '#b8bab9', color: 'black' }
  },
  {
    when: (row: { name: string }) => row.name.includes('focus.focus_night'),
    style: { backgroundColor: '#7d807e', color: 'black' }
  },
  {
    when: (row: { name: string }) => row.name.includes('camera.embedded_trigger'),
    style: { backgroundColor: '#7777f7', color: 'black' }
  },
  {
    when: (row: { name: string }) => row.name.includes('lidar.trigger_on'),
    style: { backgroundColor: '#5665fc', color: 'black' }
  },
  {
    when: (row: { name: string }) => row.name.includes('lidar.mindist'),
    style: { backgroundColor: '#76e394', color: 'black' }
  },
  {
    when: (row: { name: string }) => row.name.includes('lidar.maxdist'),
    style: { backgroundColor: '#32a852', color: 'black' }
  },
  {
    when: (row: { name: string }) => row.name.includes('automatic_brightness.enable_modul'),
    style: { backgroundColor: '#ff6600', color: 'black' }
  },
  {
    when: (row: { name: string }) => row.name.includes('maintenanceStatus'),
    style: { 'min-height': 'fit-content', 'padding-bottom': '8px', 'padding-top': '7px' }
  }
];

const columns = memoize((wales, handleActionClick, isEditing, formOnChange, reRenderNumber) => {
  const setToEdit = (row: any, index: any) => {
    if (
      [RowType.BOOLEAN, RowType.TEXT, RowType.MAINTENANCE_ARRAY, RowType.SINGLE_SELECT].includes(
        getRowType(row.name)
      )
    ) {
      handleActionClick({ row, index }, 'edit');
    }
  };
  const editableRowType = (rowName: string) => {
    const rowType = getRowType(rowName);
    if (rowType === RowType.BOOLEAN) return 'boolean';
    if (rowType === RowType.MAINTENANCE_ARRAY) return 'maintenance_array';
    if (rowType === RowType.SINGLE_SELECT) return 'single_select';
    return 'text';
  };
  const cols: any[] = wales
    .map((w: any) => ({
      sortable: true,
      id: w._id,
      grow: '2',
      width: '200px',
      name: w.shortWaleId,
      editable: true,
      cell: (row: any, index: any, column: any) => (
        <div
          data-tip
          data-for={`settingsTip-${String(get(row, 'name'))}-${reRenderNumber}`}
          onDoubleClick={() => setToEdit(row, index)}
        >
          <EditableCell
            row={row}
            column={{ ...column, editing: isEditing(row) }}
            onChange={formOnChange}
            dataType={editableRowType(row.name)}
            dropdownOptions={getRowDropdownOptions(row.name)}
          />
        </div>
      )
    }))
    .sort((a: any, b: any) => (a.name > b.name ? 1 : -1));

  cols.unshift({
    name: 'Parameter Name',
    selector: 'displayName',
    sortable: true,
    width: '550px',
    grow: '3',
    editable: false
  });

  return cols.concat([
    {
      cell: (row: any, index: any) =>
        [RowType.BOOLEAN, RowType.TEXT, RowType.MAINTENANCE_ARRAY, RowType.SINGLE_SELECT].includes(
          getRowType(row.name)
        ) ? (
          <BasicActionButtons
            item={{ row, index }}
            clickHandler={handleActionClick}
            withShow={false}
            withDestroy={false}
            editClick={!isEditing(row)}
            saveClick={isEditing(row)}
            discardClick={isEditing(row)}
            withEdit={false}
          />
        ) : undefined,
      ignoreRowClick: true,
      allowOverflow: false,
      name: 'ACTION',
      button: true,
      grow: '1',
      minWidth: '240px'
    }
  ]);
});

export default columns;
