import { WaleStatusMonitor } from 'presentation/WaleMonitor/components/StatusTable';

export const WALE_STATUS_SAMPLE: WaleStatusMonitor = {
  battery: {
    voltage: 0
  },
  disk: {
    used: 0,
    total: 1
  },
  temp: {
    cpu: 0
  },
  solar: {
    power: 0
  },
  measuredAt: null,
  targetZero: 0,
  autoBrightness: false,
  systemScheduleOn: false,
  vehicleScheduleOn: false,
  maintenanceStatus: []
};
