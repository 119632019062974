import { Wale } from 'domain/entities/wale.entity';
import { uniq, range } from 'lodash';

type DeployedState = 'CO' | 'NC';

export type RoadProject =
  | 'All'
  | 'CO-I70'
  | 'CO-C470'
  | 'CO-I25'
  | 'BW-HQ'
  | 'CO-I25SG'
  | 'CO-C70'
  | 'CO-US36'
  | 'NC-I540';

const roadProjectsByState: Record<DeployedState, Array<{ value: RoadProject; label: string }>> = {
  CO: [
    { value: 'CO-I70', label: 'I70 MEXL' },
    { value: 'CO-C470', label: 'C470' },
    { value: 'CO-I25', label: 'I25 Sections 2&3' },
    { value: 'CO-C70', label: 'Central 70' },
    { value: 'CO-I25SG', label: 'I25 South Gap' },
    { value: 'CO-US36', label: 'US 36' },
    { value: 'BW-HQ', label: 'Blissway HQ' } // HQ is in Colorado so it's shown with other CO projects
  ],
  NC: [{ value: 'NC-I540', label: 'I-540' }]
};

const commonRoadProjects: Array<{ value: RoadProject; label: string }> = [
  { value: 'All', label: 'All Projects' }
];

const getCurrentDeployedState = (): DeployedState => {
  const envState = process.env.REACT_APP_DEPLOYED_STATE ?? '';
  if (envState in roadProjectsByState) return envState as DeployedState;
  return 'CO';
};

export const roadProjects: Array<{ value: RoadProject; label: string }> = commonRoadProjects.concat(
  roadProjectsByState[getCurrentDeployedState()]
);

export const isRoadProject = (value: string): value is RoadProject =>
  roadProjects.some((project) => project.value === value);

export const isOldWaleIdFormat = (waleId: string) => waleId.split('-').length === 4;

export const getWaleLocation = (waleId: string): string => {
  const cutoffIndex = isOldWaleIdFormat(waleId) ? 3 : 4;
  const location = waleId.split('-').slice(0, cutoffIndex).join('-');

  // Remove "-M-" to normalize I70 location format due to mixed WALE ID types, e.g.
  // CO-I70E-M-238_0 -> CO-I70E-238_0
  return location.replace(/I70(W|E)-M/, 'I70$1');
};

export const getLane = (waleId?: string): number => {
  if (!waleId) return 0;
  const index = isOldWaleIdFormat(waleId) ? 3 : 4;
  return parseInt(waleId.split('-')[index].slice(-1), 10);
};
export const isFront = (waleId: string): boolean => getOrientation(waleId) === 'F';

const isBack = (waleId: string): boolean => getOrientation(waleId) === 'B';

export const getWalePair = (waleId: string): string => {
  const newDirectionCharacter = isFront(waleId) ? 'B' : 'F';
  const splitWaleId = waleId.split('-');
  const index = isOldWaleIdFormat(waleId) ? 3 : 4;
  splitWaleId[index] = splitWaleId[index].replace(getOrientation(waleId), newDirectionCharacter);

  return splitWaleId.join('-');
};

export const getOrientation = (waleId: string) => {
  const [index, offset] = isOldWaleIdFormat(waleId) ? [3, 0] : [4, 1];
  return waleId.split('-')[index].slice(offset, offset + 1);
};

export const getSideOrientation = (waleId: string) => {
  if (isOldWaleIdFormat(waleId)) return 'L';
  return waleId.split('-')[4].slice(0, 1);
};

export const isLeftWale = (waleId: string) => getSideOrientation(waleId) === 'L';

export const getRoadDirection = (id: string) => {
  const roadId = getRoad(id);
  if (roadId.includes('_')) return roadId.split('_')[1];
  return roadId.charAt(roadId.length - 1);
};

export const getRoad = (id: string) => {
  const splitted = id.split('-'); // ['CO', 'I70W', ...]
  const road = splitted.slice(0, 2).join('-'); // 'CO-I70W'
  return road;
};

export const standardRoadDirections = ['N', 'W', 'S', 'E'];
const internalRoadDirections = ['B'];
const allUsedRoadDirections = [...standardRoadDirections, ...internalRoadDirections];

const removeDirectionFromRoad = (roadId: string, direction: string) => {
  if (!roadId.endsWith(direction)) return roadId;
  if (roadId.includes('_')) {
    return roadId.split('_')[0];
  }
  return roadId.slice(0, roadId.length - direction.length);
};

export const getRoadProject = (id: string): RoadProject => {
  const road = getRoad(id);
  const roadDirection = getRoadDirection(id);
  const project = allUsedRoadDirections.includes(roadDirection)
    ? removeDirectionFromRoad(road, roadDirection)
    : road;

  if (!isRoadProject(project)) {
    return 'All';
  }

  return project;
};

const getAllRoadLocations = (road: string, allWales: string[]) => {
  const allWalesThatMatchRoad = allWales.filter((waleId) => getRoad(waleId) === road);
  const allLocations = allWalesThatMatchRoad.map((waleId) => getWaleLocation(waleId));
  const allUniqueLocations = uniq(allLocations);
  return allUniqueLocations;
};

export const parseLocationMileFromWaleId = (waleId: string): number =>
  parseLocationMileFromLocation(getWaleLocation(waleId));

export const parseLocationMileFromLocation = (locationId: string): number => {
  const fullNumber = locationId.split('-').slice(-1)[0];
  const replacedNumber = fullNumber.replace('_', '.');
  return Number(replacedNumber);
};

// TODO: take this from a corridor document if needed
const isDirectionAscendingMileage = (direction: string) => ['E', 'N'].includes(direction);

// TODO: take this from a corridor document if needed
const isRoadAscendingMileage = (roadId: string) =>
  isDirectionAscendingMileage(getRoadDirection(roadId));

const getAllRoadLocationsWithDistances = (road: string, allWales: string[]) => {
  const allUniqueLocations = getAllRoadLocations(road, allWales);
  const allLocationsWithDistances = allUniqueLocations.map((location) => ({
    location,
    mile: parseLocationMileFromLocation(location)
  }));
  // TODO: change hardcoded order for the one in corridor collection
  allLocationsWithDistances.sort(
    (a, b) => (a.mile - b.mile) * (isRoadAscendingMileage(road) ? 1 : -1)
  );
  return allLocationsWithDistances;
};

export const getLocationIndexInRoad = (location: string, waleIdList: string[]): number => {
  const allLocationsWithDistances = getAllRoadLocationsWithDistances(
    getRoad(location),
    waleIdList
  ).map((elem) => elem.location);
  return allLocationsWithDistances.findIndex((loc) => loc === location);
};

const splitLocationData = (locationId: string, waleIdList: string[]) => {
  const isAscendingMileage = isRoadAscendingMileage(locationId);
  const locationIndex = getLocationIndexInRoad(
    locationId,
    waleIdList.filter((waleId) => getRoad(waleId) === getRoad(locationId))
  );
  const roadId = getRoad(locationId);
  return { isAscendingMileage, locationIndex, roadId };
};

const splitWaleData = (waleId: string, waleIdList: string[]) => {
  const isBackWale = isBack(waleId);
  const isLeft = isLeftWale(waleId);
  const readLane = getLane(waleId);
  return {
    ...splitLocationData(getWaleLocation(waleId), waleIdList),
    isBack: isBackWale,
    isLeft,
    lane: readLane
  };
};

export const waleIdsCompareFn = (waleIdList: string[]) => (firstId: string, secondId: string) => {
  try {
    const {
      isAscendingMileage: isFirstAscendingMileage,
      locationIndex: firstLocation,
      roadId: firstRoadId,
      isBack: isFirstBack,
      isLeft: isFirstLeft,
      lane: firstLane
    } = splitWaleData(firstId, waleIdList);

    const {
      isAscendingMileage: isSecondAscendingMileage,
      locationIndex: secondLocation,
      roadId: secondRoadId,
      isBack: isSecondBack,
      isLeft: isSecondLeft,
      lane: secondLane
    } = splitWaleData(secondId, waleIdList);

    // Sort alphabetically by road
    if (firstRoadId < secondRoadId) return -1;
    if (firstRoadId > secondRoadId) return 1;

    // Descending roads first
    if (!isFirstAscendingMileage && isSecondAscendingMileage) return -1;
    if (isFirstAscendingMileage && !isSecondAscendingMileage) return 1;

    // Lower locations first
    if (firstLocation < secondLocation) return -1;
    if (firstLocation > secondLocation) return 1;

    // Back WALEs first
    if (isFirstBack && !isSecondBack) return -1;
    if (!isFirstBack && isSecondBack) return 1;

    // Left WALEs first
    if (isFirstLeft && !isSecondLeft) return -1;
    if (!isFirstLeft && isSecondLeft) return 1;

    // Left lane WALEs first
    if (firstLane < secondLane) return -1;
    if (secondLane < firstLane) return 1;

    return 0;
  } catch {
    if (firstId < secondId) return -1;
    if (firstId > secondId) return 1;
    return 0;
  }
};

const isWalePartOfMainCorridor = (waleId: string) => {
  if (isOldWaleIdFormat(waleId)) return true;
  return waleId.split('-')[2] === 'M';
};

const isOldLocationFormat = (locationId: string) => locationId.split('-').length === 3;

export const islocationIngress = (locationId: string) => {
  if (isOldLocationFormat(locationId)) return false;
  return locationId.split('-')[2][0] === 'I';
};

export const islocationEgress = (locationId: string) => {
  if (isOldLocationFormat(locationId)) return false;
  return locationId.split('-')[2][0] === 'E';
};

export const isLocationPartOfMainCorridor = (locationId: string) => {
  if (isOldLocationFormat(locationId)) return true;
  return locationId.split('-')[2] === 'M';
};

export const getLocationSubcorridorName = (locationId: string) => locationId.split('-')[2];

export const shortWaleId = (waleId: string): string => {
  try {
    const roadDirection = getRoadDirection(getRoad(waleId));
    const splitWaleId = waleId.split('-');
    if (isOldWaleIdFormat(waleId)) {
      return `${roadDirection}-${splitWaleId.slice(2, 4).join('-')}`;
    }
    if (isWalePartOfMainCorridor(waleId)) {
      return `${roadDirection}-${splitWaleId.slice(3, 5).join('-')}`;
    }
    return `${roadDirection}-${splitWaleId[2]}-${splitWaleId[4]}`;
  } catch {
    return waleId;
  }
};

export const isShortWaleIdBack = (shortId: string): boolean =>
  shortId.split('-')[2].slice(0, 2).includes('B');

export const isShortWaleIdWestbound = (shortId: string): boolean => shortId.slice(0, 1) === 'W';
export const isShortWaleIdEastbound = (shortId: string): boolean => shortId.slice(0, 1) === 'E';
export const isShortWaleIdSouthbound = (shortId: string): boolean => shortId.slice(0, 1) === 'S';
export const isShortWaleIdNorthbound = (shortId: string): boolean => shortId.slice(0, 1) === 'N';

export const getLocationHumanNameFromWaleHumanName = (humanName: string) => humanName.split('-')[0];

export const getLocationHumanNameMap = (wales: Wale[]) => {
  const returnObject: Record<string, string> = {};
  wales.forEach((wale) => {
    const waleLocation = getWaleLocation(wale.waleId);
    if (wale.humanName)
      returnObject[waleLocation] = getLocationHumanNameFromWaleHumanName(wale.humanName);
    else returnObject[waleLocation] = 'No name';
    returnObject[getIngressLocationName(waleLocation)] = `${parseLocationMileFromLocation(
      waleLocation
    ).toFixed(1)} Ingress`;
    returnObject[getEgressLocationName(waleLocation)] = `${parseLocationMileFromLocation(
      waleLocation
    ).toFixed(1)} Egress`;
  });
  return returnObject;
};

/*
 * Returns the block of the ID with the side of the road, orientation and lane number.
 * e.g. CO-I70W-242_0-B2 -> B2
 *      CO-C470W-M-023_9-RB4-G1G2 -> RB4
 */
export const getWaleIdBlockWithInfoInLocation = (waleId: string) => {
  const index = isOldWaleIdFormat(waleId) ? 3 : 4;
  return waleId.split('-')[index];
};

const getConnectingLocationName = (locationId: string, connectingCharacter: 'I' | 'E') => {
  const splitLocId = locationId.split('-');
  splitLocId[2] = connectingCharacter;
  return splitLocId.join('-');
};

export const getIngressLocationName = (locationId: string) =>
  getConnectingLocationName(locationId, 'I');
export const getEgressLocationName = (locationId: string) =>
  getConnectingLocationName(locationId, 'E');

/**
 * Starts on the lane number explicitly stated in the ID,
 * and then counts up or down depending on how many lanes
 * were declared in the last part of the ID. Can select by lane
 * type too if given, defaults to all lanes.
 */
export const getWaleLanes = (waleId?: string, laneType: 'EL' | 'GPL' | 'All' = 'All'): number[] => {
  if (!waleId) return [];
  const onlyEL = laneType === 'EL';
  const onlyGPL = laneType === 'GPL';
  if (isOldWaleIdFormat(waleId))
    return [getLane(waleId)].filter(() =>
      onlyEL || onlyGPL ? onlyEL === isWaleReadingExpressLane(waleId) : true
    );
  const firstReadLane = getLane(waleId);
  const splitWaleId = waleId.split('-');
  const readLanes = splitWaleId.slice(-1)[0];
  const side = getSideOrientation(waleId);
  const readExpressLaneCount = readLanes.replace(/[^E]/gi, '').length;
  const readGeneralLaneCount = readLanes.replace(/[^G]/gi, '').length;
  const readLaneCount = readExpressLaneCount + readGeneralLaneCount;

  if (side === 'L') {
    return range(
      firstReadLane + (onlyGPL ? readExpressLaneCount : 0),
      firstReadLane + readLaneCount - (onlyEL ? readGeneralLaneCount : 0)
    );
  }
  return range(
    firstReadLane - (readLaneCount - (onlyGPL ? readExpressLaneCount : 0)) + 1,
    firstReadLane - (onlyEL ? readGeneralLaneCount : 0) + 1
  );
};

const I70_FAST_LANE = 1;

export const isWaleReadingExpressLane = (waleId: string) => {
  if (isOldWaleIdFormat(waleId)) return getLane(waleId) === I70_FAST_LANE;
  return waleId.split('-')[5].includes('E');
};
export const isWaleReadingGeneralPurposeLane = (waleId: string) => {
  if (isOldWaleIdFormat(waleId)) return getLane(waleId) !== I70_FAST_LANE;
  return waleId.split('-')[5].includes('G');
};

export const getLocationAndLaneId = (waleId: string) =>
  `${getWaleLocation(waleId)}-${
    isOldWaleIdFormat(waleId) ? '' : getSideOrientation(waleId)
  }${getLane(waleId)}`;
