/* eslint-disable camelcase */
import { waleIdsCompareFn } from '../../presentation/helpers/waleId';

export enum MaintenanceStatus {
  CameraIssue = 'CameraIssue',
  LidarIssue = 'LidarIssue',
  SensysIssue = 'SensysIssue',
  NetworkIssue = 'NetworkIssue',
  PowerIssue = 'PowerIssue',
  OtherIssue = 'OtherIssue'
}

export const SensysTransmitIntervals = [0.03125, 0.0625, 0.125, 0.25, 0.5];
export const availableFWShutterSpeeds = [
  '1/30',
  '1/60',
  '1/120',
  '1/250',
  '1/500',
  '1/1000',
  '1/2000',
  '1/4000',
  '1/8000',
  '1/15000',
  '1/30000'
];
export const availableFWGammaValues = [
  '0.45',
  '0.5',
  '0.55',
  '0.6',
  '0.65',
  '0.7',
  '0.75',
  'Adaptive',
  'Default'
];

// Add more fields if they're needed in the front code
export interface Wale {
  _id: string;
  waleId: string;
  eboxId: string;
  name: string;
  coordinates: number[];
  active: boolean;
  isProduction: boolean;
  updatedAt: Date;
  createdAt: Date;
  previousEboxIds: {
    eboxId: string;
    start: Date;
    end?: Date;
  }[];
  humanName?: string;
  external_parameters: {
    detection_filter: {
      slope: number;
      offset: number;
      previous_filter_parameters: {
        slope: number;
        offset: number;
        start: Date;
        end?: Date;
      }[];
    };
  };
  maintenanceStatus: MaintenanceStatus[];
}

export const walesCompareFn = (wales: Wale[]) => (first: Wale, second: Wale) =>
  waleIdsCompareFn(wales.map((wale) => wale.waleId))(first.waleId, second.waleId);
